.input-file{
    position: relative;
    display: flex;
}

.input-file>input {
    position: absolute;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    height: 46px;
    width: 150px;
    margin-Top: 10px;
    max-width: 200px;
}

