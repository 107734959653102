.swiper {
       width: 100%;
}

.swiper-slide {
    width: 90px !important;
    height: 170px !important;
    margin-inline: 20px;
}

.swiper-button-next,
.swiper-button-prev {
    color: var(--black-100) !important;
}

@media only screen and (min-width: 600px) {
    .swiper-slide {
        width: 140px !important;
        height: 210px !important;
        margin-inline: 36px !important;
    }
}

@media only screen and (min-width: 900px) {
    .swiper-slide {
        width: 130px !important;
        height: 250px !important;
        margin-inline: 35px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .swiper-slide {
        width: 150px !important;
        height: 280px !important;
        margin-inline: 45px !important;

    }
}