@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Inter", sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: "320px";
}

:root {
  --black-100: #09101d;
  --btn-green: #05944f;
}

:root::-webkit-scrollbar {
  -webkit-appearance: none;
}

:root::-webkit-scrollbar:vertical {
  width: 10px;
}

:root::-webkit-scrollbar-button:increment,
:root::-webkit-scrollbar-button {
  display: none;
}

:root::-webkit-scrollbar:horizontal {
  height: 10px;
}

:root::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

:root::-webkit-scrollbar-track {
  border-radius: 10px;
}

blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #a1550fcc solid;
  font-style: italic;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
